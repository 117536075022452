import { types, flow, applySnapshot, destroy, getEnv } from "mobx-state-tree";
import { API_ERROR_MESSAGE, RESPONSE_STATUS, ROOT_URL } from "../../constants";
import { getRequestOptions } from "../../utils/RequestOptions";
import FileSaver from "file-saver";
import { fetchApi } from "../../utils/CustomFetch";
import { jsonToFormData } from "../../helpers";
import { status } from "nprogress";

const TrackPreviewLearning = types.model({
	learningId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	learningSNo: types.maybeNull(types.string),
	learning: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewRequirement = types.model({
	requirementId: types.maybeNull(types.number),
	requirementSNo: types.maybeNull(types.string),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	requirement: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewOutline = types.model({
	outlineId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	outlineSNo: types.maybeNull(types.string),
	outline: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewContinuingEducation = types.model({
	continuingEducationId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	continuingEducationSNo: types.maybeNull(types.string),
	continuingEducation: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewCourseObjective = types.model({
	trackObjectiveId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	trackObjectiveSNo: types.maybeNull(types.string),
	trackObjective: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewCertificateProgram = types.model({
	certificateProgramId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	certificateProgramSNo: types.maybeNull(types.string),
	certificateProgram: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewTargetAudience = types.model({
	targetAudienceId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	targetAudienceSNo: types.maybeNull(types.string),
	targetAudience: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewAdditionalInformation = types.model({
	additionalInformationId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	additionalInformationSNo: types.maybeNull(types.string),
	additionalInformation: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewCourseMaterial = types.model({
	trackMaterialId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	trackMaterialSNo: types.maybeNull(types.string),
	trackMaterial: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewInstructor = types.model({
	instructorId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	instructorSNo: types.maybeNull(types.string),
	instructorHeadShot: types.maybeNull(types.string),
	instructorName: types.maybeNull(types.string),
	instructorCV: types.maybeNull(types.string),
	instructorRating: types.maybeNull(types.number),
	instructorReview: types.maybeNull(types.number),
	students: types.maybeNull(types.number),
	courses: types.maybeNull(types.number),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewSchedule = types.model({
	scheduleId: types.maybeNull(types.number),
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	scheduleSNo: types.maybeNull(types.string),
	scheduleTime: types.maybeNull(types.string),
	scheduleDate: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
});

const TrackPreviewHeaderImage = types.model({
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackPreviewImageContentType: types.maybeNull(types.string),
	trackPreviewImageFile: types.maybeNull(types.string),
	trackPreviewImageUrl: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
});

const TrackPreviewVideo = types.model({
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackPreviewVideoContentType: types.maybeNull(types.string),
	trackPreviewVideoLink: types.maybeNull(types.string),
	isLocationS3: types.maybeNull(types.boolean),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	trackPreviewSNo: types.maybeNull(types.string),
	id: types.maybeNull(types.number),
	sNo: types.maybeNull(types.string),
});

const TrackPreviewBrochure = types.model({
	trackPreviewId: types.maybeNull(types.number),
	brochureContentType: types.maybeNull(types.string),
	brochureFile: types.maybeNull(types.string),
	brochureURL: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	trackPreviewSNo: types.maybeNull(types.string),
	brochureId: types.maybeNull(types.number),
});

const ImageResponse = types.model({
	id: types.maybeNull(types.number),
	activityPreviewId: types.maybeNull(types.number),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	activityPreviewImageFile: types.maybeNull(types.string),
	activityPreviewImageContentType: types.maybeNull(types.string),
	activityPreviewImageUrl: types.maybeNull(types.string),
})

const TrackCourseItemDetail = types.model({
	trackCourseId: types.maybeNull(types.number),
	trackCourseSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
	batchId: types.maybeNull(types.number),
	batchSNo: types.maybeNull(types.string),
	sequenceNo: types.maybeNull(types.number),
	courseName: types.maybeNull(types.string),
	batchName: types.maybeNull(types.string),
	batchType: types.maybeNull(types.string),
	courseTitle: types.maybeNull(types.string),
	coursePreviewImageResponse: types.maybeNull(
		ImageResponse
	),
	startDate: types.maybeNull(types.string),
	description: types.maybeNull(types.string),
});

const TrackPreviewPresenter = types.model({
	presenterId: types.maybeNull(types.number),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	prefix: types.maybeNull(types.string),
	firstName: types.maybeNull(types.string),
	middleName: types.maybeNull(types.string),
	lastName: types.maybeNull(types.string),
	biography: types.maybeNull(types.string),
	edit: types.optional(types.boolean, false),
	courseId: types.maybeNull(types.number),
	courseSNo: types.maybeNull(types.string),
})
const TrackPreviewDetails = types.model({
	trackPreviewId: types.maybeNull(types.number),
	trackPreviewSNo: types.maybeNull(types.string),
	trackId: types.maybeNull(types.number),
	trackSNo: types.maybeNull(types.string),
	trackDescription: types.maybeNull(types.string),
	trackDeliveryMethod: types.maybeNull(types.string),
	previewTemplateId: types.maybeNull(types.number),
	imageTitle1: types.maybeNull(types.string),
	imageTitle2: types.maybeNull(types.string),
	imageTitle3: types.maybeNull(types.string),
	trackTitle1: types.maybeNull(types.string),
	trackTitle2: types.maybeNull(types.string),
	isDisplayOnLandingPage: types.maybeNull(types.boolean),
	isDisplayOnMarketPlace: types.maybeNull(types.boolean),
	price: types.maybeNull(types.number),
	discountedPrice: types.maybeNull(types.number),
	language: types.maybeNull(types.number),
	skillLevel: types.maybeNull(types.number),
	registrationDeadline: types.maybeNull(types.string),
	isCDP: types.maybeNull(types.boolean),
	certificate: types.maybeNull(types.boolean),
	trackPreviewLearning: types.maybeNull(types.array(TrackPreviewLearning)),
	trackPreviewHeaderImage: types.maybeNull(TrackPreviewHeaderImage),
	trackPreviewVideo: types.maybeNull(TrackPreviewVideo),
	trackPreviewBrochure: types.maybeNull(TrackPreviewBrochure),
	trackPreviewRequirement: types.maybeNull(
		types.array(TrackPreviewRequirement)
	),
	trackPreviewOutline: types.maybeNull(types.array(TrackPreviewOutline)),
	trackPreviewSchedule: types.maybeNull(types.array(TrackPreviewSchedule)),
	trackPreviewContinuingEducation: types.maybeNull(
		types.array(TrackPreviewContinuingEducation)
	),
	trackPreviewCourseObjective: types.maybeNull(
		types.array(TrackPreviewCourseObjective)
	),
	trackPreviewCertificateProgram: types.maybeNull(
		types.array(TrackPreviewCertificateProgram)
	),
	trackPreviewTargetAudience: types.maybeNull(
		types.array(TrackPreviewTargetAudience)
	),

	trackPreviewAdditionalInformation: types.maybeNull(
		types.array(TrackPreviewAdditionalInformation)
	),

	trackPreviewCourseMaterial: types.maybeNull(
		types.array(TrackPreviewCourseMaterial)
	),

	trackPreviewInstructor: types.maybeNull(
		types.array(TrackPreviewInstructor)
	),
	trackPreviewPresenter: types.maybeNull(
		types.array(TrackPreviewPresenter)
	),
	tags: types.maybeNull(types.array(types.string)),
	trackCourses: types.maybeNull(types.array(TrackCourseItemDetail)),
});

const PresenterHeadshotItem = types.model({
	presenterId: types.number,
	headshot: types.string,
});

// const TrackPreviewMainImage = types.model({
// 	id: types.maybeNull(types.number),
// 	sNo: types.maybeNull(types.string),
// 	trackId: types.maybeNull(types.number),
// 	trackSNo: types.maybeNull(types.string),
// 	trackPreviewId: types.maybeNull(types.number),
// 	trackPreviewSNo: types.maybeNull(types.string),
// 	trackPreviewImageFile: types.maybeNull(types.string),
// 	trackPreviewImageContentType: types.maybeNull(types.string),
// 	trackPreviewImageUrl: types.maybeNull(types.string),
// })
// const PostPreSurveyItem = types.model({
// 	surveyId: types.maybeNull(types.number),
// 	// surveyDueDate: types.maybeNull(types.string),
// 	surveyName: types.maybeNull(types.string),
// 	isMandatory: types.maybeNull(types.boolean),
// 	isSubmitted: types.maybeNull(types.boolean),
// 	dueDate: types.maybeNull(types.string),
// 	assignedSurveyType:types.maybeNull(types.number),
// })
// const SessionLinkItem = types.model({
// 	sessionLinkAddress: types.maybeNull(types.string),
// 	sessionLinkId: types.maybeNull(types.number),
// 	sessionLinkTitle: types.maybeNull(types.string),
// 	sessionLinkThumbnail: types.maybeNull(types.string)
// });
// const SurveyItem = types.model({
// 	surveyId: types.maybeNull(types.number),
// 	dueDate: types.maybeNull(types.string),
// 	surveyName: types.maybeNull(types.string),
// 	assignedSurveyType:types.maybeNull(types.number),
// 	isSubmitted: types.maybeNull(types.boolean),
// });
// const UploadItem = types.model({
// 	uploadId: types.maybeNull(types.number),
// 	uploadType: types.maybeNull(types.string),
// 	uploadURL: types.maybeNull(types.string),
// 	uploadContentType: types.maybeNull(types.string),
// 	uploadFile: types.maybeNull(types.string),
// 	uploadSection: types.maybeNull(types.number),
// });
// const SessionItem = types.model({
// 	name: types.maybeNull(types.string),
// 	sessionId: types.maybeNull(types.number),
// 	sessionSNo: types.maybeNull(types.string),
// 	sessionUpload: types.array(UploadItem),
// 	sessionLink: types.array(SessionLinkItem),
// 	sessionSurvey: types.array(SurveyItem),
// });
// const DaysItem = types.model({
// 	courseDaysId: types.maybeNull(types.number),
// 	courseDaysSNo: types.maybeNull(types.string),
// 	courseSession: types.array(SessionItem),
// 	name: types.maybeNull(types.string),
// });
// const CourseLinkItem = types.model({
// 	courseLinkAddress: types.maybeNull(types.string),
// 	courseLinkId: types.maybeNull(types.number),
// 	courseLinkSection: types.maybeNull(types.number),
// 	courseLinkTitle: types.maybeNull(types.string),
// 	courseLinkThumbnail: types.maybeNull(types.string)
// });

// const CoursePreviewMainImage = types.model({
// 	activityPreviewId: types.maybeNull(types.number),
// 	activityPreviewImageContentType: types.maybeNull(types.string),
// 	activityPreviewImageFile: types.maybeNull(types.string),
// 	activityPreviewImageUrl: types.maybeNull(types.string),
// 	courseId: types.maybeNull(types.number),
// 	courseSNo: types.maybeNull(types.string),
// 	id: types.maybeNull(types.number),
// });
// const TrackCourseItem = types.model({
// 	courseId: types.maybeNull(types.number),
// 	courseSNo: types.maybeNull(types.string),
// 	courseName: types.maybeNull(types.string),
// 	userInstructions: types.maybeNull(types.string),
// 	batchId: types.maybeNull(types.number),
// 	batchSNo: types.maybeNull(types.string),
// 	batchName: types.maybeNull(types.string),
// 	isZoomEnabled: types.maybeNull(types.boolean),
// 	isZoomPaused: types.maybeNull(types.boolean),
// 	isZoomButtonVisible: types.maybeNull(types.boolean),
// 	zoomMeetingId: types.maybeNull(types.number),
// 	zoomMeetingTitle: types.maybeNull(types.string),
// 	nextLiveSession: types.maybeNull(types.string),
// 	nextLiveSessionDate: types.maybeNull(types.string),
// 	isZoomExpired: types.maybeNull(types.boolean),
// 	title: types.maybeNull(types.string),
// 	subTitle: types.maybeNull(types.string),
// 	miniTitle: types.maybeNull(types.string),
// 	courseTitle2: types.maybeNull(types.string),
// 	courseDays:types.array(DaysItem),
// 	courseLink:types.array(CourseLinkItem),
// 	courseUpload:types.array(UploadItem),
// 	coursePreparatorySurveys:types.array(PostPreSurveyItem),
// 	coursePostSurveys:types.array(PostPreSurveyItem),
// 	coursePreviewMainImage:types.maybeNull(CoursePreviewMainImage),
// })
// const BeginTrackJourney = types.model({
// 	trackId: types.maybeNull(types.number),
// 	trackSNo: types.maybeNull(types.string),
// 	tRackDescription: types.maybeNull(types.string),
// 	trackName: types.maybeNull(types.string),
// 	trackUserInstructions:types.maybeNull(types.string),
// 	nextLiveSession:types.maybeNull(types.string),
// 	nextLiveSessionDate:types.maybeNull(types.string),
// 	title:types.maybeNull(types.string),
// 	subTitle:types.maybeNull(types.string),
// 	miniTitle:types.maybeNull(types.string),
// 	trackTitle2:types.maybeNull(types.string),
// 	trackPreviewMainImage:types.maybeNull(TrackPreviewMainImage),
// 	trackPreparatorySurveys:types.array(PostPreSurveyItem),
// 	trackPostSurveys:types.array(PostPreSurveyItem),
// 	patientViewCourses:types.array(TrackCourseItem),
// })
export const TrackPreview = types
	.model({ 
		details: types.maybeNull(TrackPreviewDetails) ,
		// beginTrackJourney:types.maybeNull(BeginTrackJourney),
		presenterHeadshots: types.array(PresenterHeadshotItem),
	})
	.actions((self) => {
		return {
			load: flow(function* load(track, snackbar, callback) {
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Common/getTrackPreviewById?trackId=${track?.trackId}&trackSNo=${track?.trackSNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.details = json;
						if (callback) {
							callback();
						}
						self.status = "done";
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadForBeginTrack: flow(function* loadForBeginTrack(
				trackId,
				trackSNo,
				snackbar,
				callback
			){
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getTrackByIdForPatient?trackId=${trackId}&trackSNo=${trackSNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.beginTrackJourney = json;
						self.status = "done";
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			loadForBeginTrackPreview: flow(function* loadForBeginTrackPreview(
				trackId,
				trackSNo,
				snackbar,
				callback
			){
				self.status = "pending";
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getTrackByIdForPreview?trackId=${trackId}&trackSNo=${trackSNo}`,
						getRequestOptions("GET", null)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.beginTrackJourney = json;
						self.status = "done";
						callback();
					} else {
						snackbar.showMessage(json?.error, null, null, {
							severity: "error",
						});
						self.status = "error";
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
					self.status = "error";
				}
			}),
			uploadImage: flow(function* uploadImage(
				data,
				track,
				snackbar,
				callback
			) {
				if (self.details?.trackPreviewId) {
					try {
						const fd = new FormData();
						fd.append("fileUpload.formFile", data);
						fd.append(
							"trackPreviewId",
							self.details?.trackPreviewId
						);
						fd.append(
							"trackPreviewSNo",
							self.details?.trackPreviewSNo
						);
						fd.append("TrackId", track.trackId);
						fd.append("TrackSNo", track.trackSNo);

						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewHeaderImage`,
							getRequestOptions("POST", fd, true)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.details.trackPreviewHeaderImage = json;
							getEnv(self).notifier.enqueue(
								"File Uploaded",
								"success"
							);
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				} else {
					try {
						const fd = new FormData();
						fd.append("fileUpload.formFile", data);
						fd.append("TrackId", track.trackId);
						fd.append("TrackSNo", track.trackSNo);

						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewHeaderImageWithoutId`,
							getRequestOptions("POST", fd, true)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.details.trackPreviewHeaderImage = json;
							self.details.trackPreviewId = json.trackPreviewId;
							self.details.trackPreviewSNo = json.trackPreviewSNo;
							getEnv(self).notifier.enqueue(
								"File Uploaded",
								"success"
							);
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				}
			}),

			removeImage: flow(function* removeImage(
				trackPreviewHeaderImage,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteTrackPreviewImage`,
						getRequestOptions("POST", trackPreviewHeaderImage)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(trackPreviewHeaderImage);
							getEnv(self).notifier.enqueue(
								"File Deleted",
								"success"
							);
						}
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			uploadVideo: flow(function* uploadVideo(
				data,
				file,
				track,
				snackbar,
				callback
			) {
				if (data?.isLocationS3) {
					try {
						const fd = new FormData();
						fd.append("fileUpload.formFile", file);
						fd.append(
							"TrackPreviewId",
							self.details?.trackPreviewId
						);
						fd.append(
							"TrackPreviewSNo",
							self.details?.trackPreviewSNo
						);
						fd.append("TrackId", track.current?.trackId);
						fd.append("TrackSNo", track.current?.trackSNo);
						fd.append("IsLocationS3", true);

						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewVideo`,
							getRequestOptions("POST", fd, true)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.details = {
								...self.details,
								trackPreviewVideo: json,
							};
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							getEnv(self).notifier.enqueue(
								"File Uploaded",
								"success"
							);
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				} else {
					try {
						const fd = new FormData();
						fd.append("VideoLink", data?.videoLink);
						fd.append("TrackId", track.current?.trackId);
						fd.append("TrackSNo", track.current?.trackSNo);
						fd.append("IsLocationS3", false);
						fd.append(
							"TrackPreviewId",
							self.details?.trackPreviewId
						);
						fd.append(
							"TrackPreviewSNo",
							self.details?.trackPreviewSNo
						);

						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewVideo`,
							getRequestOptions("POST", fd, true)
						);
						const json = yield response.json();
						if (response.status === RESPONSE_STATUS.SUCCESS) {
							self.details = {
								...self.details,
								trackPreviewVideo: json,
							};
							snackbar.showMessage(
								"Record Successfully Updated!!",
								null,
								null,
								{ severity: "success" }
							);
							callback();
						} else {
							snackbar.showMessage(json?.error, null, null, {
								severity: "error",
							});
							callback();
						}
					} catch (error) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
						callback();
					}
				}
			}),

			removeVideo: flow(function* removeVideo(
				trackPreviewVideo,
				snackbar
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteTrackPreviewVideo`,
						getRequestOptions("POST", trackPreviewVideo)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(trackPreviewVideo);
							getEnv(self).notifier.enqueue(
								"File Deleted",
								"success"
							);
						}
					}
				} catch (error) {
					snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
						severity: "error",
					});
				}
			}),

			uploadBrochure: flow(function* uploadBrochure(
				file,
				track,
				callback
			) {
				try {
					const fd = new FormData();
					fd.append("fileUpload.FormFile", file);
					fd.append("TrackPreviewId", self.details?.trackPreviewId);
					fd.append("TrackPreviewSNo", self.details?.trackPreviewSNo);
					fd.append("TrackId", track.trackId);
					fd.append("TrackSNo", track.trackSNo);

					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/addTrackBrochureUpload`,
						getRequestOptions("POST", fd, true)
					);

					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						self.details = {
							...self.details,
							coursePreviewBrochure: json,
						};
						getEnv(self).notifier.enqueue(
							"File Uploaded",
							"success"
						);
						callback();
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),

			removeBrochure: flow(function* removeBrochure(brochure) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/deleteTrackBrochure`,
						getRequestOptions("POST", brochure)
					);
					const json = yield response.json();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (json.status === 200) {
							destroy(brochure);
							getEnv(self).notifier.enqueue(
								"File Deleted",
								"success"
							);
						}
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
				}
			}),

			getFileAsDownload: flow(function* getFileAsDownload(
				track,
				callback,
				fileURL,
				fileContentType
			) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/getBrochureAsDownload?fileURL=${fileURL}&fileContentType=${fileContentType}`,
						getRequestOptions("GET", null)
					);
					const blob = yield response.blob();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						FileSaver.saveAs(blob, `Brochure`);
						callback();
					} else {
						getEnv(self).notifier.enqueue(
							"Unable to download File",
							"error"
						);
						callback();
					}
				} catch (error) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),

			save: flow(function* save(data, snackbar) {
				if (self.details?.trackPreviewId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreview`,
							getRequestOptions("POST", {
								...self.details,
								...data,
							})
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								self.details = json;
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/createTrackPreview`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							self.details = json;
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveDescription: flow(function* saveDescription(data, snackbar) {
				if (data?.trackPreviewId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewDescription`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveLearning: flow(function* saveLearning(data, snackbar) {
				if (data?.learningId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewLearning`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewLearning.find(
										(item) =>
											item.learningId === data.learningId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewLearning`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.trackPreviewLearning) {
								self.details?.trackPreviewLearning.push(json);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewLearning: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeLearning: flow(function* removeLearning(data, snackbar) {
				if (data?.learningId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewLearning`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewLearning.find(
										(item) =>
											item.learningId === data.learningId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			saveRequirement: flow(function* saveRequirement(data, snackbar) {
				if (data?.requirementId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewRequirement`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewRequirement.find(
										(item) =>
											item.requirementId ===
											data.requirementId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewRequirement`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);

							if (self.details?.trackPreviewRequirement) {
								self.details?.trackPreviewRequirement.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewRequirement: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeRequirement: flow(function* removeRequirement(
				data,
				snackbar
			) {
				if (data?.requirementId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewRequirement`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewRequirement.find(
										(item) =>
											item.requirementId ===
											data.requirementId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editLearningItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewLearning.find(
						(learning) => learning.learningId === item.learningId
					),
					{
						...self.details?.trackPreviewLearning.find(
							(learning) =>
								learning.learningId === item.learningId
						),
						edit: edit,
					}
				);
			},

			editRequirementItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewRequirement.find(
						(requirement) =>
							requirement.requirementId === item.requirementId
					),
					{
						...self.details?.trackPreviewRequirement.find(
							(requirement) =>
								requirement.requirementId === item.requirementId
						),
						edit: edit,
					}
				);
			},

			saveOutline: flow(function* saveOutline(data, snackbar) {
				if (data?.outlineId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewOutline`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewOutline.find(
										(item) =>
											item.outlineId ===
											data.outlineId
									),
									{
										...json,
									}
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewOutline`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.trackPreviewOutline) {
								self.details?.trackPreviewOutline.push(json);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewOutline: [
										{
											...json,
										},
									],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeOutline: flow(function* removeOutline(data, snackbar) {
				if (data?.outlineId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewOutline`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewOutline.find(
										(item) =>
											item.outlineId ===
											data.outlineId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editOutlineItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewOutline.find(
						(trackOutline) =>
							trackOutline.outlineId === item.outlineId
					),
					{
						...self.details?.trackPreviewOutline.find(
							(trackOutline) =>
								trackOutline.outlineId ===
								item.outlineId
						),
						edit: edit,
					}
				);
			},

			saveContinuingEducation: flow(function* saveContinuingEducation(
				data,
				snackbar
			) {
				if (data?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewContinuingEducation`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewContinuingEducation.find(
										(item) =>
											item.continuingEducationId ===
											data.continuingEducationId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/AddTrackPreviewContinuingEducation`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.trackPreviewContinuingEducation) {
								self.details?.trackPreviewContinuingEducation.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewContinuingEducation: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeContinuingEducation: flow(function* removeContinuingEducation(
				data,
				snackbar
			) {
				if (data?.continuingEducationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewContinuingEducation`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewContinuingEducation.find(
										(item) =>
											item.continuingEducationId ===
											data.continuingEducationId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editContinuingEducationItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewContinuingEducation.find(
						(continuingEducation) =>
							continuingEducation.continuingEducationId ===
							item.continuingEducationId
					),
					{
						...self.details?.trackPreviewContinuingEducation.find(
							(continuingEducation) =>
								continuingEducation.continuingEducationId ===
								item.continuingEducationId
						),
						edit: edit,
					}
				);
			},

			saveCourseObjective: flow(function* saveCourseObjective(
				data,
				snackbar
			) {
				if (data?.trackObjectiveId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewCourseObjective`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewCourseObjective.find(
										(item) =>
											item.trackObjectiveId ===
											data.trackObjectiveId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/AddTrackPreviewCourseObjective`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.trackPreviewCourseObjective) {
								self.details?.trackPreviewCourseObjective.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewCourseObjective: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeCourseObjective: flow(function* removeCourseObjective(
				data,
				snackbar
			) {
				if (data?.trackObjectiveId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewCourseObjective`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewCourseObjective.find(
										(item) =>
											item.trackObjectiveId ===
											data.trackObjectiveId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editCourseObjectiveItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewCourseObjective.find(
						(trackObjective) =>
							trackObjective.trackObjectiveId ===
							item.trackObjectiveId
					),
					{
						...self.details?.trackPreviewCourseObjective.find(
							(trackObjective) =>
								trackObjective.trackObjectiveId ===
								item.trackObjectiveId
						),
						edit: edit,
					}
				);
			},

			saveCertificateProgram: flow(function* saveCertificateProgram(
				data,
				snackbar
			) {
				if (data?.certificateProgramId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewCertificateProgram`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewCertificateProgram.find(
										(item) =>
											item.certificateProgramId ===
											data.certificateProgramId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/AddTrackPreviewCertificateProgram`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.trackPreviewCertificateProgram) {
								self.details?.trackPreviewCertificateProgram.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewCertificateProgram: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeCertificateProgram: flow(function* removeCertificateProgram(
				data,
				snackbar
			) {
				if (data?.certificateProgramId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewCertificateProgram`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewCertificateProgram.find(
										(item) =>
											item.certificateProgramId ===
											data.certificateProgramId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editCertificateProgramItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewCertificateProgram.find(
						(trackObjective) =>
							trackObjective.certificateProgramId ===
							item.certificateProgramId
					),
					{
						...self.details?.trackPreviewCertificateProgram.find(
							(trackObjective) =>
								trackObjective.certificateProgramId ===
								item.certificateProgramId
						),
						edit: edit,
					}
				);
			},

			saveTargetAudience: flow(function* saveTargetAudience(
				data,
				snackbar
			) {
				if (data?.targetAudienceId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewTargetAudience`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewTargetAudience.find(
										(item) =>
											item.targetAudienceId ===
											data.targetAudienceId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewTargetAudience`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.trackPreviewTargetAudience) {
								self.details?.trackPreviewTargetAudience.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewTargetAudience: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeTargetAudience: flow(function* removeTargetAudience(
				data,
				snackbar
			) {
				if (data?.targetAudienceId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewTargetAudience`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewTargetAudience.find(
										(item) =>
											item.targetAudienceId ===
											data.targetAudienceId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editTargetAudienceItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewTargetAudience.find(
						(targetAudience) =>
							targetAudience.targetAudienceId ===
							item.targetAudienceId
					),
					{
						...self.details?.trackPreviewTargetAudience.find(
							(targetAudience) =>
								targetAudience.targetAudienceId ===
								item.targetAudienceId
						),
						edit: edit,
					}
				);
			},

			saveAdditionalInformation: flow(function* saveAdditionalInformation(
				data,
				snackbar
			) {
				if (data?.additionalInformationId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewAdditionalInformation`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewAdditionalInformation.find(
										(item) =>
											item.additionalInformationId ===
											data.additionalInformationId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewAdditionalInformation`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (
								self.details?.trackPreviewAdditionalInformation
							) {
								self.details?.trackPreviewAdditionalInformation.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewAdditionalInformation: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeAdditionalInformation: flow(
				function* removeAdditionalInformation(data, snackbar) {
					if (data?.additionalInformationId) {
						try {
							const response = yield fetchApi(
								`${ROOT_URL}/api/Course/deleteTrackPreviewAdditionalInformation`,
								getRequestOptions("POST", data)
							);
							const json = yield response.json();
							if (response.status === 200) {
								if (json.status === 400) {
									snackbar.showMessage(
										json.error,
										null,
										null,
										{
											severity: "error",
										}
									);
								} else {
									destroy(
										self.details?.trackPreviewAdditionalInformation.find(
											(item) =>
												item.additionalInformationId ===
												data.additionalInformationId
										)
									);
									snackbar.showMessage(
										"Record Deleted Successfully!!",
										null,
										null,
										{ severity: "success" }
									);
								}
							} else {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							}
						} catch (e) {
							snackbar.showMessage(
								API_ERROR_MESSAGE,
								null,
								null,
								{
									severity: "error",
								}
							);
						}
					}
				}
			),

			editAdditionalInformationItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewAdditionalInformation.find(
						(additionalInformation) =>
							additionalInformation.additionalInformationId ===
							item.additionalInformationId
					),
					{
						...self.details?.trackPreviewAdditionalInformation.find(
							(additionalInformation) =>
								additionalInformation.additionalInformationId ===
								item.additionalInformationId
						),
						edit: edit,
					}
				);
			},

			saveCourseMaterial: flow(function* saveCourseMaterial(
				data,
				snackbar
			) {
				if (data?.trackMaterialId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewCourseMaterial`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewCourseMaterial.find(
										(item) =>
											item.trackMaterialId ===
											data.trackMaterialId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewCourseMaterial`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.trackPreviewCourseMaterial) {
								self.details?.trackPreviewCourseMaterial.push(
									json
								);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewCourseMaterial: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeCourseMaterial: flow(function* removeCourseMaterial(
				data,
				snackbar
			) {
				if (data?.trackMaterialId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewCourseMaterial`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewCourseMaterial.find(
										(item) =>
											item.trackMaterialId ===
											data.trackMaterialId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editCourseMaterialItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewCourseMaterial.find(
						(trackMaterial) =>
							trackMaterial.trackMaterialId ===
							item.trackMaterialId
					),
					{
						...self.details?.trackPreviewCourseMaterial.find(
							(trackMaterial) =>
								trackMaterial.trackMaterialId ===
								item.trackMaterialId
						),
						edit: edit,
					}
				);
			},

			saveInstructor: flow(function* saveInstructor(data, snackbar) {
				if (data?.instructorId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewInstructor`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewInstructor.find(
										(item) =>
											item.instructorId ===
											data.instructorId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewInstructor`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.trackPreviewInstructor) {
								self.details?.trackPreviewInstructor.push(json);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewInstructor: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeInstructor: flow(function* removeInstructor(data, snackbar) {
				if (data?.instructorId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewInstructor`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewInstructor.find(
										(item) =>
											item.instructorId ===
											data.instructorId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editInstructorItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewInstructor.find(
						(instructor) =>
							instructor.instructorId === item.instructorId
					),
					{
						...self.details?.trackPreviewInstructor.find(
							(instructor) =>
								instructor.instructorId === item.instructorId
						),
						edit: edit,
					}
				);
			},

			savePresenter: flow(function* savePresenter(data, snackbar) {
				if (data?.presenterId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateCoursePreviewPresenter`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewPresenter.find(
										(item) =>
											item.presenterId ===
											data.presenterId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} 
			}),
			loadPresenterHeadshot: flow(function* loadPresenterHeadshot(data) {
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/GetPresenterHeadShotBase64?presenterId=${data?.presenterId}&courseId=${data?.courseId}&courseSNo=${data?.courseSNo}`,
						getRequestOptions("GET", null)
					);
					const base64 = yield response.text();
					if (response.status === RESPONSE_STATUS.SUCCESS) {
						if (
							self.presenterHeadshots.some(
								(headshot) =>
									headshot.presenterId === data.presenterId
							)
						) {
							self.presenterHeadshots.find(
								(headshot) =>
									headshot.presenterId === data.presenterId
							).headshot = 'data:image/png;base64,' + base64;
						} else {
							self.presenterHeadshots.push({
								presenterId: data.presenterId,
								headshot: 'data:image/png;base64,' + base64,
							});
						}
					}
				} catch (error) { }
			}),
			addPresenterHeadshot: flow(function* addPresenterHeadshot(data, callback) {
				const fd = jsonToFormData(data);
				try {
					const response = yield fetchApi(
						`${ROOT_URL}/api/Course/AddPresenterHeadshot`,
						getRequestOptions("POST", fd, true)
					);
					const json = yield response.json();
					if (response.status === 200) {
						if (json.status !== 0) {
							getEnv(self).notifier.enqueue(json?.error, "error");
							callback();
						} else {
							callback();
						}
					} else {
						getEnv(self).notifier.enqueue(json?.error, "error");
						callback();
					}
				} catch (e) {
					getEnv(self).notifier.enqueue(API_ERROR_MESSAGE, "error");
					callback();
				}
			}),
			editPresenterItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewPresenter.find(
						(presenter) =>
							presenter.presenterId === item.presenterId
					),
					{
						...self.details?.trackPreviewPresenter.find(
							(presenter) =>
								presenter.presenterId === item.presenterId
						),
						edit: edit,
					}
				);
			},
			removePresenter: flow(function* removePresenter(data, snackbar) {
				if (data?.presenterId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteCoursePreviewPresenter`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewPresenter.find(
										(item) =>
											item.presenterId ===
											data.presenterId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),
			saveSchedule: flow(function* saveSchedule(data, snackbar) {
				if (data?.scheduleId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/updateTrackPreviewSchedule`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								applySnapshot(
									self.details?.trackPreviewSchedule.find(
										(item) =>
											item.scheduleId === data.scheduleId
									),
									json
								);
								snackbar.showMessage(
									"Record Successfully Updated!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				} else {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/addTrackPreviewSchedule`,
							getRequestOptions("POST", data)
						);

						const json = yield response.json();
						if (response.status === 200 && json.status === 0) {
							snackbar.showMessage(
								"Record Successfully Saved!!",
								null,
								null,
								{ severity: "success" }
							);
							if (self.details?.trackPreviewSchedule) {
								self.details?.trackPreviewSchedule.push(json);
							} else {
								applySnapshot(self.details, {
									...self.details,
									trackPreviewSchedule: [json],
								});
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			removeSchedule: flow(function* removeSchedule(data, snackbar) {
				if (data?.scheduleId) {
					try {
						const response = yield fetchApi(
							`${ROOT_URL}/api/Course/deleteTrackPreviewSchedule`,
							getRequestOptions("POST", data)
						);
						const json = yield response.json();
						if (response.status === 200) {
							if (json.status === 400) {
								snackbar.showMessage(json.error, null, null, {
									severity: "error",
								});
							} else {
								destroy(
									self.details?.trackPreviewSchedule.find(
										(item) =>
											item.scheduleId === data.scheduleId
									)
								);
								snackbar.showMessage(
									"Record Deleted Successfully!!",
									null,
									null,
									{ severity: "success" }
								);
							}
						} else {
							snackbar.showMessage(json.error, null, null, {
								severity: "error",
							});
						}
					} catch (e) {
						snackbar.showMessage(API_ERROR_MESSAGE, null, null, {
							severity: "error",
						});
					}
				}
			}),

			editScheduleItem(item, edit) {
				applySnapshot(
					self.details?.trackPreviewSchedule.find(
						(schedule) => schedule.scheduleId === item.scheduleId
					),
					{
						...self.details?.trackPreviewSchedule.find(
							(schedule) =>
								schedule.scheduleId === item.scheduleId
						),
						edit: edit,
					}
				);
			},
		};
	});
